import React from 'react'

export default function index() {
	return (
		<main className="thankyou">
			<h1>Thank you for for your interest</h1>
			<p>I'll be in touch shortly</p>
		</main>
	)
}
