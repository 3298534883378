import React from 'react'

export default function index() {
	return (
		<div>
			<h1>Something went wrong</h1>
			<p>Sorry the form didn't work as expected.</p>
			<p>If you could reach out to me at: mercuryspringberry@gmail.com I would still be interested in hearing from you.</p>
			
		</div>
	)
}
