import React from 'react'

export default function Heading() {
    return (
        <div className="heading">
            <h1>Jerry White</h1>
            <h2>Pyrography</h2>
            
        </div>
    )
}
